<template>
  <v-card>
    <v-card-title>
      <div class="d-flex align-center justify-center" style="gap: 8px;">
        <v-icon size="30px">mdi-view-list</v-icon>
        <span class="text-uppercase text-subtitle-1 font-weight-bold" style="word-break: break-word;">Documentación y servicios</span>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row class="mx-auto">
        <v-col v-for="(accion, index) in acciones" :key="index" cols="12" sm="6" md="3" xl="2" >
          <div class="text-center">
            <slot v-if="accion.name" :name="`item.${accion.name}`" :item="accion">
              <PerfilItemComponent v-bind="accion"  />
            </slot>
            <PerfilItemComponent v-else v-bind="accion" class="mx-auto" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import PerfilItemComponent from '../PerfilItemComponent.vue';

export default {
  name: 'AccionesPerfil',
  components: { PerfilItemComponent },
  props: {
    proveedor: { type: Object },
    esProveedor: { type: Boolean, default: true },
  },
  computed: {
    accionesProveedor() {
      if (!this.proveedor) return [];

      const acciones = [];

      if (this.haveRoles(['ROLE_PROVEEDOR_MANTENIMIENTO_ACTIVIDAD_LISTAR', 'ROLE_MANTENIMIENTO_ACTIVIDAD_LISTAR'])) {
        acciones.push({ icon: 'mdi-account-cash', label: 'Actividades', name: 'actividades'  });
      }
      
      acciones.push({ icon: 'mdi-text-box-plus-outline', label: 'Documentación', route: 'documentos' });
      acciones.push({ icon: 'mdi-phone-outline', label: 'Contactos', route: 'contactos' });

      if (!([1].includes(this.proveedor.identificacion_tributaria?.id_tipo_contribuyente) && [1, 2].includes(this.proveedor.id_clasificacion_empresa))) {
        acciones.push({ icon: 'mdi-account-group-outline', label: 'Accionistas', route: 'accionistas' });
      }

      if (this.haveRole('ROLE_GESTION_EMPLEADOS_PROVEEDOR') && !this.haveRole('ROLE_PROVEEDOR_EMPLEADO')) {
        acciones.push({
          icon: 'mdi-account-multiple-check',
          label: 'Perfiles',
          route: 'empleadosProveedor',
          params: { ...(!this.haveRole('ROLE_PROVEEDOR') && { id_proveedor: this.proveedor.id_usuario }) },
        });
      }

      if (this.haveRole('ROLE_PROVEEDOR') && !this.haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])) {
        acciones.push({ icon: 'mdi-alert-outline', label: 'Sanciones', route: 'sanciones-proveedor', params: { id_proveedor: this.proveedor.id_usuario } });
      }

      return acciones;
    },
    accionesAdministrador() {
      if (!this.proveedor) return [];

      const acciones = [];
      
      if (this.haveRoles(['ROLE_PROVEEDOR_MANTENIMIENTO_ACTIVIDAD_LISTAR', 'ROLE_MANTENIMIENTO_ACTIVIDAD_LISTAR'])) {
        acciones.push({ icon: 'mdi-account-cash', label: 'Actividades', name: 'actividades' });
      }

      acciones.push({ icon: 'mdi-text-box-plus-outline', label: 'Documentación', route: 'documentacion-proveedor', params: { id_proveedor: this.proveedor.id_usuario } });
      acciones.push({ icon: 'mdi-phone-outline', label: 'Contactos', route: 'contactos-proveedor', params: { id_proveedor: this.proveedor.id_usuario } });

      if (!([1].includes(this.proveedor.identificacion_tributaria?.id_tipo_contribuyente) && [1, 2].includes(this.proveedor.id_clasificacion_empresa))) {
        acciones.push({ icon: 'mdi-account-group-outline', label: 'Accionistas', route: 'accionistas', params: { idProveedor: this.proveedor.id_usuario } });
      }

      if (this.haveRole('ROLE_GESTION_EMPLEADOS_PROVEEDOR') && !this.haveRole('ROLE_PROVEEDOR_EMPLEADO')) {
        acciones.push({ icon: 'mdi-account-multiple-check', label: 'Perfiles', route: 'empleadosProveedor', params: { id_proveedor: this.proveedor.id_usuario } });
      }

      if (this.haveRoles(['ROLE_TECNICO_DIRECCION_COMPRAS', 'ROLE_DIRECCION_COMPRAS', 'ROLE_CALLCENTER', 'ROLE_VISTA_PROCESOS_ODC_RUPES']) && !this.haveRole('ROLE_PROVEEDOR')) {
        acciones.push({ icon: 'mdi-alert-outline', label: 'Sanciones', route: `/proveedor/${this.proveedor.id_usuario}/sancion` });
        acciones.push({ icon: 'mdi-file-tree-outline', label: 'Procesos', route: 'procesos-proveedor', params: { id_proveedor: this.proveedor.id_usuario } });
        acciones.push({ icon: 'mdi-file-sign', label: 'Contratos', route: 'contratos-proveedor', params: { id_proveedor: this.proveedor.id_usuario } });
      }

      acciones.push({ icon: 'mdi-comment-outline', name: 'resena', label: 'Reseña', route: '/resena' });

      return acciones;
    },
    acciones() {
      return this.esProveedor ? this.accionesProveedor : this.accionesAdministrador;
    },
  },

}
</script>
<style lang="scss" scoped>
:deep(.item-container) {
  width:150px !important;
}

:deep(.item-container:hover) {
  background: #313945;
  color: white !important;
  border-radius: 4px;
  .v-icon {
    color: white !important;
  }
}
</style>
