<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-4 py-4 align-center justify-space-between" style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="d-flex">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal"
                        text
                        rounded
                        :disabled="actualizandoAvatar"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <v-btn 
                    v-if="avatar" 
                    color="primary"
                    :loading="actualizandoAvatar"
                    @click.stop="actualizarAvatar"
                >
                    Guardar
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-4 py-4">
                <v-file-input
                    v-if="!soloLectura"
                    v-model="avatar"
                    label="Foto"
                    accept="image/*"
                    placeholder="Avatar"
                    class="mb-4"
                    outlined
                    hide-details
                />
                
                <EmptyComponent 
                    :empty="!avatarSrc && !src"
                    colored
                    text="Foto no cargada."
                    icon="mdi-image-off"                   
                >
                    <v-img
                        max-width="100%"
                        max-height="500px"
                        :src="avatarSrc ?? src"
                        contain
                    >
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5" />
                            </v-row>
                        </template>
                    </v-img>
                </EmptyComponent>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import { mapActions } from 'vuex';

export default {
    name: 'ModalAvatar',
    emits: ['on-save'],
    components: { EmptyComponent },
    props: {
        isOpen: { type: Boolean },
        src: { type: String },
        proveedor: { type: Object },
        soloLectura: { type: Boolean, default: false },
    },
    data: () => ({
        avatar: null,
        actualizandoAvatar: false,
    }),
    computed: {
        avatarSrc() {
            if (!this.avatar) return null;

            return URL.createObjectURL(this.avatar);
        },
    },
    methods: {
        // UI
        ...mapActions("proveedores", ["uploadProviderLogo"]),
        cerrarModal() {
            this.$emit('update:is-open', false);
            this.avatar = null;
        },
        // DATA
        async actualizarAvatar() {
            try {
                this.actualizandoAvatar = true;
                await this.uploadProviderLogo({ id: this.proveedor.id, image: this.avatar });
                this.$emit('on-save');
                this.pushAppMessage({ type: 'success', message: 'Logo actualizado correctamente.' });
                this.cerrarModal();
            } finally {
                this.actualizandoAvatar = false;
            }
        },
    },
}
</script>