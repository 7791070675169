<template>
  <router-link 
    :to="{ name: route, params: params }" 
    class="text-decoration-none px-4 py-4 d-flex flex-column align-center item-container"
  >
      <v-icon
        color="primary"
        size="75px"
        class="btns"
      >
        {{ icon }}
      </v-icon>
      <p class="text-center mt-4 mb-0"> {{ label }} </p>
  </router-link>
</template>

<script>
export default {
  name: 'PerfilItemComponent',
  props: {
    params: {
      default: null,
    },
    icon: {
      typeof: String,
    },
    route: {
      typeof: String,
    },
    label: {
      typeof: String,
    },
  },
};
</script>