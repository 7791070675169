<template>
    <v-card>
        <v-card-title class="justify-space-between" style="gap: 16px;">
          <div class="d-flex align-center justify-center" style="gap: 8px;">
            <v-icon size="30px">mdi-account</v-icon>
            <span class="text-uppercase text-subtitle-1 font-weight-bold" style="word-break: break-word;">
              Información del perfil
            </span>
          </div>
            <v-chip 
            v-if="perfilCompleto && idProveedor == null" 
            color="success" 
            class="white--text" 
            label
          >
            Información completa
          </v-chip>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div v-if="!perfilCompleto" class="mb-4">
            <template v-if="esProveedor">
              <h3>
                <v-icon color="warning">mdi-alert-circle</v-icon>
                Tu perfil está incompleto
              </h3>
              <p>
                Para poder aplicar a las ofertas debes completar los campos en las
                opciones de: OBS, giro empresarial, documentación, accionistas y
                contacto
              </p>
            </template>
            <v-progress-linear
              color="#FFEAA7"
              :value="progresoPerfil"
              readonly
              height="25"
            >
              <strong>{{ Math.ceil(progresoPerfil) }} %</strong>
            </v-progress-linear>
          </div>

          <div v-if="proveedor">
            <div>
              <v-badge avatar overlap bottom offset-x="25" offset-y="35">
                <template v-slot:badge>
                  <span 
                    :style="`width: 36px; height: 36px; background-color: #d8dbe0; border-radius: 50%; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); cursor: ${cargandoLogo ? 'default' : 'pointer'};`"
                    class="d-flex align-center justify-center elevation-2" 
                    @click.stop="cargandoLogo ? null : mostrarImagenCompleta()"
                  >
                    <v-icon size="18" color="primary">
                      {{ soloLectura ? 'mdi-eye' : 'mdi-camera' }}
                    </v-icon>
                  </span>
                </template>

                <v-avatar 
                  color="#d8dbe0" 
                  size="125"
                  @click.stop="cargandoLogo ? null : mostrarImagenCompleta()"
                  :style="`cursor: ${cargandoLogo ? 'default' : 'pointer'};`"
                >
                <v-progress-circular v-if="cargandoLogo" indeterminate color="#adb6c4" :size="50" :width="2" />
                <template v-else>
                    <v-img
                      v-if="providerLogo"
                      max-height="300"
                      max-width="300"
                      :src="avatarPerfil"
                      :lazy-src="avatarPerfil"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                      </template>
                    </v-img>
                    <v-icon v-else>mdi-image</v-icon>
                  </template>
                </v-avatar>
              </v-badge>
            </div>

            <div class="mt-4 d-flex align-center flex-wrap" style="gap: 8px;">
              <v-btn v-if="!soloLectura" color="primary" @click.stop="modalActualizarPerfilAbierta = true">
                <v-icon color="white" class="mr-2">mdi-pencil</v-icon>
                Editar perfil
              </v-btn>
              <slot name="acciones"></slot>
            </div>

            <v-row class="mt-4" dense no-gutters>
              <v-col cols="12" md="6">
                <dl>
                  <dt class="font-weight-bold text-uppercase text-subtitle-2">Nombre del proveedor</dt>
                  <dd class="text-body-2">{{ proveedor.nombre || '-' }}</dd>

                  <dt class="font-weight-bold text-uppercase text-subtitle-2 mt-3">Nombre comercial</dt>
                  <dd class="text-body-2">{{ proveedor.nombre_comercial || '-' }}</dd>

                  <dt class="font-weight-bold text-uppercase text-subtitle-2 mt-3">Tipo de persona</dt>
                  <dd class="text-body-2">{{ proveedor.tipo_contribuyente || '-' }}</dd>

                  <dt class="font-weight-bold text-uppercase text-subtitle-2 mt-3">Correo electrónico</dt>
                  <dd class="text-body-2">{{ proveedor.email || '-' }}</dd>

                  <dt class="font-weight-bold text-uppercase text-subtitle-2 mt-3">{{ getDocumentName(proveedor.documentos) }}</dt>
                  <dd class="text-body-2">{{ getDocumentNumber(proveedor.documentos) || '-' }}</dd>

                  <dt class="font-weight-bold text-uppercase text-subtitle-2 mt-3">Género</dt>
                  <dd class="text-body-2">{{ proveedor.genero || '-' }}</dd>
                </dl>
              </v-col>
              <v-col cols="12" md="6" class="mt-3 mt-md-0">
                <dl>
                  <dt class="font-weight-bold text-uppercase text-subtitle-2">Sitio web</dt>
                  <dd class="text-body-2">{{ proveedor.sitio_web || "No posee" }}</dd>

                  <dt class="font-weight-bold text-uppercase text-subtitle-2 mt-3">Clasificación de la empresa</dt>
                  <dd class="text-body-2">{{ proveedor.clasificacion_empresa || '-' }}</dd>

                  <dt class="font-weight-bold text-uppercase text-subtitle-2 mt-3">Teléfono</dt>
                  <dd class="text-body-2">
                    {{ 
                      proveedor.telefono
                      ? `${proveedor.area + " " + proveedor.telefono}` 
                      : '-'
                    }}
                  </dd>

                  <dt class="font-weight-bold text-uppercase text-subtitle-2 mt-3">
                    ¿Está constituida como una SAS?
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="secondary" size="20" style="transform: translateY(-1px); cursor: pointer;">mdi-information</v-icon>
                      </template>
                      <span>SAS: sociedad por acciones simplificada</span>
                    </v-tooltip>
                  </dt>
                  <dd class="text-body-2">{{ proveedor.sas ? 'Si' : 'No' }}</dd>

                  <dt class="font-weight-bold text-uppercase text-subtitle-2 mt-3">Dirección</dt>
                  <dd class="text-body-2">{{ getAddress(proveedor.direccion) }}</dd>
                </dl>
              </v-col>
            </v-row>

          </div>

          <ModalAvatar 
            :is-open.sync="modalVerAvatarAbierta" 
            :solo-lectura="soloLectura"
            :proveedor="proveedor"
            :src="avatarPerfil" 
            @on-save="cargarLogoProveedor(proveedor.id)"
          />
          <ModalActualizarPerfil 
            :is-open.sync="modalActualizarPerfilAbierta" 
            :proveedor="provider"
            :es-proveedor="esProveedor"
            :avatar="avatarPerfil"
            @on-save="manejarGuardadoPerfil"
          />
        </v-card-text>
      </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import ModalAvatar from "./ModalAvatar.vue";
import ModalActualizarPerfil from "./ModalActualizarPerfil.vue";

export default {
    name: 'InformacionPerfil',
    components: { ModalAvatar, ModalActualizarPerfil },
    emits: ['on-save'],
    props: {
        perfilCompleto: { type: Boolean },
        progresoPerfil: { type: Number },
        idProveedor: { type: [String, Number] },
        colorBtnEdicion: { type: String, default: 'primary' },
        esProveedor: { type: Boolean, default: true },
        soloLectura: { type: Boolean, default: false },
    },
    data: () => ({
      modalVerAvatarAbierta: false,
      modalActualizarPerfilAbierta: false,
      cargandoLogo: false,
    }),
    computed: {
        ...mapState("proveedores", ["provider", "providerLogo"]),
        proveedor() {
          return this.provider;
        },
        avatarPerfil() {
          return this.providerLogo ?? null;
        },
    },
    methods: {
        ...mapActions("proveedores", ["getProviderLogo"]),
        // UI
        getDocumentName(data) {
            return (data && data.length > 0) ? data[0]?.TipoDocumento?.nombre : "";
        },
        getDocumentNumber(data) {
            return (data && data.length > 0) ? data[0]?.numero_documento : "";
        },
        getAddress(data) {
          if (!data) return '-';
          
          return `
            ${data?.pais}${data?.departamento ? ", " + data?.departamento : ""}
            ${data?.municipio ? ", " + this.getMunicipioName(data?.municipio) : ""}, 
            ${data?.detalle_direccion}
          `;
        },
        getMunicipioName(data) {
          const name = data?.split(" ");
          name?.pop();

          return name?.join(" ");
        },
        async cargarLogoProveedor(idProveedor) {
          try {
            this.cargandoLogo = true;
            await this.getProviderLogo(idProveedor);
          } finally {
            this.cargandoLogo = false;
          }
        },
        // UI
        mostrarImagenCompleta() {
          this.modalVerAvatarAbierta = true;
        },
        manejarGuardadoPerfil() {
          this.$emit('on-save');
        },
    },
    watch: {
      proveedor: {
        handler(valor) {
          if (!valor?.id) return;
  
          this.cargarLogoProveedor(valor.id);
        },
        deep: true,
        immediate: true,
      },
    },
}
</script>
<style scoped>
.image-input {
  max-width: 500px;
}

.text-title {
  font-size: 12px;
  margin: 0;
}

.text-info {
  font-size: 16px;
  margin: 0;
}

.espace {
  padding-bottom: 10px;
}

.input-space {
  padding-top: 50px;
}
</style>
