<template>
    <v-dialog v-model="isOpen" max-width="1400px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 py-0 flex-column " style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="px-4 py-4 d-flex" style="width: 100%">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <v-divider style="width: 100%" />
            </v-card-title>
            <v-card-text class="px-4 pt-4 pb-0">
              <v-form ref="form" @submit.prevent="manejarEnvioFormulario">
                <!-- Información general -->
                <div class="d-flex align-center" style="gap: 8px;">
                  <v-icon style="transform: translateY(-2px)">mdi-account-settings</v-icon>
                  <h6 class="text-subtitle-1 font-weight-bold text-uppercase" style="word-break: break-word;">Información general</h6>
                </div>
                <v-divider class="mt-2" />
                <v-row class="mt-4" dense no-gutters>
                  <v-col cols="12" md="6">
                    <v-text-field 
                      v-model="formulario.nombre"
                      v-bind="propiedadesCampoNombre.bind"
                      v-on="propiedadesCampoNombre.on"
                      label="Nombre del proveedor" 
                      class="pr-md-2"
                      outlined 
                    />
  
                    <v-text-field 
                      v-model="formulario.nombre_comercial"
                      v-bind="propiedadesCampoNombre_comercial.bind"
                      v-on="propiedadesCampoNombre_comercial.on"
                      label="Nombre comercial"
                      class="mt-4 pr-md-2"
                      outlined
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field 
                      v-model="formulario.email"
                      v-bind="propiedadesCampoEmail.bind"
                      v-on="propiedadesCampoEmail.on"
                      label="Correo electrónico" 
                      class="mt-4 mt-md-0 pl-md-2"
                      outlined
                    />
  
                    <v-text-field 
                      v-model="formulario.sitio_web"
                      v-bind="propiedadesCampoSitio_web.bind"
                      v-on="propiedadesCampoSitio_web.on"
                      label="Sitio web" 
                      class="mt-4 pl-md-2"
                      outlined 
                    />
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-checkbox 
                      v-model="formulario.sas"
                      label="¿Está constituida como una sociedad por acciones simplificada (SAS)?" 
                      hide-details
                    />
                  </v-col>
                </v-row>
                <!-- Información general -->
  
                <!-- Dirección -->
                <div class="d-flex align-center mt-8" style="gap: 8px;">
                  <v-icon style="transform: translateY(-2px)">mdi-map-marker</v-icon>
                  <h6 class="text-subtitle-1 font-weight-bold text-uppercase" style="word-break: break-word;">Dirección</h6>
                </div>
                <v-divider class="mt-2" />
                <v-row class="mt-4" dense no-gutters>
                  <v-col cols="12" :md="formulario.id_pais === 68 ? 3 : 6">
                    <v-autocomplete 
                      v-model="formulario.id_pais"
                      v-bind="propiedadesCampoId_pais.bind"
                      v-on="propiedadesCampoId_pais.on"
                      @change="manejarCambioPais"
                      label="País" 
                      item-value="id"
                      item-text="nombre"
                      class="pr-md-2"
                      :items="paises.data"
                      :loading="paises.isLoading" 
                      clearable
                      outlined 
                    />
                  </v-col>

                  <v-col v-if="formulario.id_pais === 68" cols="12" md="3">
                    <v-autocomplete
                      v-model="formulario.id_departamento"
                      v-bind="propiedadesCampoId_departamento.bind"
                      v-on="propiedadesCampoId_departamento.on"
                      @change="manejarCambioDepartamento"
                      label="Departamento" 
                      item-value="id"
                      item-text="nombre" 
                      class="mt-4 mt-md-0"
                      :items="departamentos.data"
                      :loading="departamentos.isLoading"
                      clearable
                      outlined 
                    />
                  </v-col>

                  <v-col v-if="formulario.id_pais === 68" cols="12" md="3">
                    <v-autocomplete
                      v-model="formulario.id_municipio"
                      v-bind="propiedadesCampoId_municipio.bind"
                      v-on="propiedadesCampoId_municipio.on"
                      label="Municipio" 
                      item-value="id"
                      item-text="nombre" 
                      class="mt-4 mt-md-0 pl-md-2"
                      :items="municipios.data"
                      :loading="municipios.isLoading" 
                      :disabled="!formulario.id_departamento"
                      clearable
                      outlined 
                    />
                  </v-col>

                  <v-col cols="12" :md="formulario.id_pais === 68 ? 3 : 6">
                    <v-text-field
                      v-model="formulario.telefono"
                      v-bind="propiedadesCampoTelefono.bind"
                      v-on="propiedadesCampoTelefono.on"
                      label="Teléfono" 
                      class="mt-4 mt-md-0 pl-md-2 telefono-input"
                      outlined
                    >
                      <template v-slot:append>
                        <span v-if="codigoArea" style="height: inherit; transform: translateY(3px); color: #ababab">
                          {{ codigoArea }}
                        </span>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="formulario.direccion"
                      v-bind="propiedadesCampoDireccion.bind"
                      v-on="propiedadesCampoDireccion.on"
                      label="Dirección"
                      class="mt-4"
                      rows="4"
                      outlined
                      no-resize
                    />
                  </v-col>
                </v-row>
                <!-- Dirección -->

                <!-- Acciones -->
                <div style="position: sticky; bottom: 0; z-index: 20; background: #fff;" class="px-3 py-4">
                  <div 
                    class="d-flex align-center justify-center align-center flex-wrap flex-sm-nowrap" 
                    style="gap: 16px;"
                  >
                    <v-btn 
                      @click.stop="cerrarModal" 
                      color="secondary" 
                      class="flex-grow-1"
                      style="flex-basis: 50%;"
                      large
                    >
                      Cerrar
                    </v-btn>
                    <v-btn 
                      color="primary" 
                      class="flex-grow-1"
                      style="flex-basis: 50%;"
                      type="submit"
                      large
                    >
                      Guardar
                    </v-btn>
                  </div>
                </div>
                <!-- Acciones -->
              </v-form>
            </v-card-text>
            <ConfirmationModalComponent 
              :is-open="confirmacionEdicion"
              :is-loading="guardandoCambios"
              description="¿Desea guardar los cambios?"
              @cancel="confirmacionEdicion = false"
              @confirm="guardarCambiosPerfil"
            />
        </v-card>
    </v-dialog>
</template>
<script>
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { required, maxLength, helpers, requiredIf } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation';
import { mapActions, mapMutations, mapState } from 'vuex';
import { Observable } from '@/utils/observable';

const url = helpers.regex(
  "url",
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
);

const correo = helpers.regex(
  "email",
  /^[a-zA-Z\d]+[a-zA-Z\d._-]*@([a-zA-Z\d]+(-|.)?){0,3}[a-zA-Z\d]+(\.[a-zA-Z\d]+)?(\.[a-zA-Z\d]{2,})$/
);

const direccion = helpers.regex("direccion", /^[0-9A-Za-zñÑáéíóúÁÉÍÓÚ,.#_/\-' ]*$/);

const alfanumerico = helpers.regex("alpha", /^[a-zA-ZÀ-ÿ0-9-_.,#\u00f1\u00d1 ]*$/);

const telefonoNacional = helpers.regex("telefono_nacional", /^(2|6|7)\d{3}-\d{4}$/);

const telefonoInternacional = helpers.regex("telefono_internacional", /^(\d+|(\d+-?\d+)+)$/);

const estadoInicialForm = {
  nombre: null,
  nombre_comercial: null,
  sitio_web: null,
  email: null,
  id_clasificacion_empresa: null,
  direccion: null,
  telefono: null,
  id_pais: null,
  id_departamento: null,
  id_municipio: null,
  sas: false,
};

export default {
    name: 'ModalAvatar',
    emits: ['on-save'],
    components: { ConfirmationModalComponent },
    validations() {
      const esNacional = this.formulario.id_pais === 68;
      
      return ({
        formulario: {
          nombre: { required, maxLength: maxLength(100), regex: alfanumerico },
          nombre_comercial: { required, maxLength: maxLength(100), regex: alfanumerico },
          sitio_web: { regex: url },
          email: { required, regex: correo },
          id_clasificacion_empresa: { required },
          direccion: { required, maxLength: maxLength(100), regex: direccion },
          telefono: { regex: esNacional ? telefonoNacional : telefonoInternacional },
          id_pais: { required },
          id_departamento: {
            required: requiredIf(function () {
              return this.formulario.id_pais === 68;
            }),
          },
          id_municipio: {
            required: requiredIf(function () {
              return this.formulario.id_pais === 68;
            }),
          },
        }
      });
    },
    props: {
      isOpen: { type: Boolean },
      proveedor: { type: Object },
      esProveedor: { type: Boolean, default: true },
    },
    data: () => ({
      // UI
      confirmacionEdicion: false,
      guardandoCambios: false,
      formulario: { ...estadoInicialForm },
      formularioCache: null,
      cargandoClasificaciones: false,
      paises: createLoadable([]),
      departamentos: createLoadable([]),
      municipios: createLoadable([]),
    }),
    computed: {
      ...mapState("registroProveedor", ["clasificacion_empresarial"]),
      codigoArea() {
        const codigoAreaPais = this.paises.data.find((pais) => pais.id === this.formulario.id_pais);

        return codigoAreaPais?.codigo_area;
      },
      grupos() {
        return ({
          general: ['nombre', 'nombre_comercial', 'sitio_web', 'email', 'id_clasificacion_empresa'],
          direccion: ['direccion', 'telefono', 'id_pais', 'id_departamento', 'id_municipio'],
        })
      },
      // FORM ERRORS
      propiedadesCampoNombre() {
        const errores = new Validator(this.$v.formulario.nombre).detect({ regex: 'El nombre debe ser un valor válido.' }).getResult();

        return this.mapearPropiedadesCampoFormulario('nombre', errores, 'general');
      },
      propiedadesCampoNombre_comercial() {
        const errores = new Validator(this.$v.formulario.nombre_comercial).detect({ regex: 'El nombre comercial debe ser un valor válido.' }).getResult();

        return this.mapearPropiedadesCampoFormulario('nombre_comercial', errores, 'general');
      },
      propiedadesCampoSitio_web() {
        const errores = new Validator(this.$v.formulario.sitio_web).detect({ regex: 'El sitio web debe ser válido.' }).getResult();

        return this.mapearPropiedadesCampoFormulario('sitio_web', errores, 'general');
      },
      propiedadesCampoEmail() {
        const errores = new Validator(this.$v.formulario.email).detect({ regex: 'El correo electrónico debe ser válido.' }).getResult();

        return this.mapearPropiedadesCampoFormulario('email', errores, 'general');
      },
      propiedadesCampoId_clasificacion_empresa() {
        const errores = new Validator(this.$v.formulario.id_clasificacion_empresa).detect().getResult();

        return this.mapearPropiedadesCampoFormulario('id_clasificacion_empresa', errores, 'general');
      },
      propiedadesCampoDireccion() {
        const errores = new Validator(this.$v.formulario.direccion).detect({ regex: 'La dirección debe ser válida.' }).getResult();

        return this.mapearPropiedadesCampoFormulario('direccion', errores, 'direccion');
      },
      propiedadesCampoTelefono() {
        const errores = new Validator(this.$v.formulario.telefono).detect({ regex: 'El teléfono debe ser válido.' }).getResult();

        return this.mapearPropiedadesCampoFormulario('telefono', errores, 'direccion');
      },
      propiedadesCampoId_pais() {
        const errores = new Validator(this.$v.formulario.id_pais).detect().getResult();

        return this.mapearPropiedadesCampoFormulario('id_pais', errores, 'direccion');
      },
      propiedadesCampoId_departamento() {
        const errores = new Validator(this.$v.formulario.id_departamento).detect().getResult();

        return this.mapearPropiedadesCampoFormulario('id_departamento', errores, 'direccion');
      },
      propiedadesCampoId_municipio() {
        const errores = new Validator(this.$v.formulario.id_municipio).detect().getResult();

        return this.mapearPropiedadesCampoFormulario('id_municipio', errores, 'direccion');
      },
    },
    methods: {
      ...mapActions("registroProveedor", ["obtener_clasificaciones_empresariales"]),
      ...mapMutations("cmSolicitudesConObservacion", ["clearRequestsToModify"]),
      ...mapMutations(["setToken", "setSelectedUnidad", "setUnidades"]),
      ...mapMutations("catalogoEnLinea", ["clearInCartRequest"]),
      ...mapActions(["setInstitucionesUsuario"]),
      ...mapMutations("utils", ["setMenu"]),
        // UI
        mapearPropiedadesCampoFormulario(nombre, errores, nombreGrupo) {
          const grupo = this.grupos[nombreGrupo];
          const hayErrorEnGrupo = Array.isArray(grupo) ? grupo.some((campo) => this.$v.formulario[campo].$error) : false;

          return ({
            bind: {
              'error-messages': errores,
              'hide-details': !hayErrorEnGrupo,
            },
            on: {
              change: () => this.$v.formulario[nombre].$touch(),
              blur: () => this.$v.formulario[nombre].$touch(),
            }
          })
        },
        limpiarFormulario() {
          this.$v.$reset();
          this.$refs.form.reset();
        },
        cerrarModal() {
          this.limpiarFormulario();
          this.$emit('update:is-open', false);
        },
        async manejarEnvioFormulario() {
          this.$v.$touch();

          if (this.$v.$invalid) return;

          this.formularioCache = { ...this.formulario };
          this.cerrarModal();
          this.confirmacionEdicion = true;
        },
        manejarCambioPais(idPais) {
          if (!idPais || idPais !== 68) {
            this.departamentos = createLoadable([]);
            this.municipios = createLoadable([]);
            this.formulario.id_departamento = null;
            this.formulario.id_municipio = null;
            return;
          }

          this.cargarDepartamentos(idPais);
        },
        manejarCambioDepartamento(idDepartamento) {
          if (!idDepartamento) {
            this.municipios = createLoadable([]);
            this.formulario.id_municipio = null;
            return;
          }

          this.cargarMunicipios(idDepartamento);
        },
        // DATA
        async getPaises() {
          try {
            toggleLoadable(this.paises);
            const response = await this.services.Paises.getListaPaises();

            setLoadableResponse(this.paises, { data: response?.data ?? [] });
          } catch (error) {
            setLoadableResponse(this.paises, { error: { message: 'Error al cargar la lista de países.' } });
          }
        },
        async cargarDepartamentos(idPais) {
          if (!idPais) return;

          try {
            toggleLoadable(this.departamentos);
            const response = await this.services.Departamentos.getDepartamentosList({ id_pais: idPais });

            setLoadableResponse(this.departamentos, { data: response?.data ?? [] });
          } catch (error) {
            setLoadableResponse(this.departamentos, { error: { message: 'Error al cargar la lista de departamentos.' } });
          }
        },
        async cargarMunicipios(idDepartamento) {
          if (!idDepartamento) return;

          try {
            toggleLoadable(this.municipios);
            const response = await this.services.Municipios.getMunicipiosList({ id_departamento: idDepartamento });

            setLoadableResponse(this.municipios, { data: response?.data ?? [] });
          } catch (error) {
            setLoadableResponse(this.municipios, { error: { message: 'Error al cargar la lista de municipios.' } });
          }
        },
        async cargarClasificacionesEmpresariales() {
          try {
            this.cargandoClasificaciones = true;
            await this.obtener_clasificaciones_empresariales();
          } finally {
            this.cargandoClasificaciones = false;
          }
        },
        async guardarCambiosPerfil() {
          try {
            this.guardandoCambios = true;
            const email = this.formularioCache.email;
            delete this.formularioCache.email;
            const esNacional = this.formularioCache.id_pais === 68;

            const response = await this.services.Proveedores.updateProviderInfo({ 
              ...this.formularioCache,
              ...email !== this.proveedor.email && ({ email }),
              ...!esNacional && ({ id_departamento: null, id_municipio: null }),
              ...!this.esProveedor && ({ id_proveedor: this.proveedor.id_usuario }),
            });

            if (response.status == 200) {
              if (this.proveedor.email !== email && this.esProveedor) {
                this.pushAppMessage({
                  message: "Se deslogueará para que pueda ingresar con su nueva dirección de correo",
                  type: "info",
                });
  
                  this.setMenu([]);
                  this.setToken(null);
                  this.setSelectedUnidad({});
                  this.setInstitucionesUsuario([]);
                  this.setUnidades([]);
                  this.clearInCartRequest();
                  this.clearRequestsToModify();
                  const tokenID = localStorage.getItem("tokenID");
                  this.services.Auth.logout(tokenID);
                  Observable.emit("logout", { "proveedor-logout": true });
                  localStorage.clear();
                  this.$router.push({ name: "identidad-digital" }).catch((e) => {});
              } else {
                this.$emit('on-save');
                this.pushAppMessage({
                  message: "Los cambios han sido guardados.",
                  type: "success",
                });
              }
            }
          } finally {
            this.confirmacionEdicion = false;
            this.guardandoCambios = false;
          }
        },
    },
    watch: {
      isOpen: {
        handler(valor) {
          if (!valor) return;
          
          if (!this.proveedor || Object.keys(this.proveedor).length === 0) {
            this.formulario = { ...estadoInicialForm };
            return;
          }

          const esNacional = this.proveedor.id_pais === 68;

          if (esNacional) {
            this.cargarDepartamentos(this.proveedor.direccion.id_pais);
            this.cargarMunicipios(this.proveedor.direccion.id_departamento);
          }

          this.formulario = {
            nombre: this.proveedor.nombre,
            nombre_comercial: this.proveedor.nombre_comercial,
            sitio_web: this.proveedor.sitio_web,
            email: this.proveedor.email,
            sas: this.proveedor.sas,
            id_clasificacion_empresa: this.proveedor.id_clasificacion_empresa,
            direccion: this.proveedor.direccion.detalle_direccion,
            telefono: this.proveedor.telefono,
            id_pais: this.proveedor.direccion.id_pais,
            id_departamento: this.proveedor.direccion.id_departamento,
            id_municipio: this.proveedor.direccion.id_municipio,
          };
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      this.cargarClasificacionesEmpresariales();
      this.getPaises();
    },
}
</script>
